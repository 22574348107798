<script setup>
import {useRouter} from "vue-router";
import {Notify, Toast} from "vant";
import dd from "gdt-jsapi"
import {onMounted, ref} from "vue";
import {
  isCreate,
  queryClientWorkorder,
  requestWorkorderProgressing,
  requestWorkorderUnconverted
} from "../../api/workorder";
//专有钉钉
const urlParam = getUrlParam();
const router = useRouter()
const isWorkorder = ref(false)

const toNavigation = () => {
  router.push({
    path: '/workorder/prepare',
    query: urlParam
  })
}
const toClientworkorder = () => {
  urlParam.clientCellphone = localStorage.getItem("createWorkorderPhone")
  router.push({
    path: 'clientworkorder',
    query: urlParam
  })
}
const scanCode = () => {
  dd.scan({type:"qr"}).then(res=>{
    // 原生js跳转
    window.location.href = res.text
  })
}


//查询客户下的工单列表
const queryClientWorkorderlist = () => {
  let clientCellphone = localStorage.getItem("createWorkorderPhone") || null
  if ( clientCellphone ){
    queryClientWorkorder({clientCellphone}).then(res => {
      let { records } = res.data
      if (records.length > 0) {
        isWorkorder.value = true
      }
    })
  }
};




onMounted(() => {
  dd.showTitleBar()
  queryClientWorkorderlist()


  // const deviceid = urlParam.id
  // requestWorkorderProgressing(deviceid).then(res => {
  //   if (!!res.data && res.data.length > 0) {
  //     // 根据设备查询未完成的工单
  //     urlParam.workorderId = res.data[0].id;
  //     workorderId.value = res.data[0].id;
  //     isZpok.value = "ok";
  //     console.log(isZpok.value)
  //   } else {
  //     requestWorkorderUnconverted(deviceid).then(res => {
  //       // 根据设备查询未指派的工单
  //       urlParam.workorderId = res.data[0].id;
  //       workorderId.value = res.data[0].id;
  //       isZpok.value = "zp";
  //       console.log(isZpok.value)
  //     });
  //   }
  // })




});

</script>

<template>
  <div class="repair_tool">
    <div class="tool_icon" @click="scanCode">
      <img src="@/asset/image/scan-code-repair.png" alt="扫码报修">
    </div>
    <div class="tool_icon" @click="toNavigation">
      <img src="@/asset/image/hand-repair.png" alt="手动报修">
    </div>
    <div class="badge" @click="toClientworkorder" v-if="isWorkorder">
      查看报修进度 >>
    </div>
  </div>
</template>

<style scoped>
.repair_tool{
  height: 100vh;
  background: #f5f5f5;
  position: relative;
}
.tool_icon{
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 13%;
}
.tool_icon img{
  display: block;
  width: 100%;
  margin: 0 auto;
}
.badge{
  width: 300px;
  position: absolute;
  top: 10px;
  right: 18px;
  text-align: right;
  font-size: 15px;
  color: #3e72ff;
  font-family: "微软雅黑";
}

</style>